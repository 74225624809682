<template>
    <section class="geolocation-alert">
        <b-icon icon="exclamation-triangle" class="icon"></b-icon>

        <template v-if="state === 1">
            โปรดทำการอนุญาตการเข้าถึงข้อมูลพิกัดตำแหน่งของท่าน เพื่อแสดงผลลัพธ์ในการเรียงลำดับร้านค้าที่ใกล้ที่สุดในตำแหน่งของท่าน
        </template>
        <template v-if="state === 2">
            เนื่องจากท่านได้ทำการปิดการอนุญาตสำหรับขอดึงข้อมูลพิกัดตำแหน่ง ดังนั้นผลลัพธ์ในการเรียงลำดับร้านค้าจะไม่สามารถเรียงผลลัพธ์ร้านค้าที่ใกล้ที่สุดในตำแหน่งของท่านได้ ถ้าหากท่านเปิดการอนุญาตในขอดึงข้อมูลพิกัดตำแหน่งแล้วโปรดทำการโหลดหน้านี้อีกครั้ง
        </template>
    </section>
</template>

<script>
export default {
    props: {
        state: Number
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.geolocation-alert {
    position: relative;
    padding: 10px 30px 10px 60px;
    background: #ffdd00;
    color: #666;
    font-size: 13px;

    @media only screen and (max-width: $screenExtraSmall) {
        font-size: 12px;
    }

    .icon {
        position: absolute;
        left: 20px;
        color: #666;
        font-size: 22px;
    }
}
</style>