<template>
    <section class="search-tag">
        <div class="tag" v-for="(item, index) in tags" :key="index">
            <span v-if="!item.link">{{ item.text }}</span>
            <router-link :to="item.link" v-if="item.link">{{ item.text }}</router-link>
            <button @click="removeTag(item)" v-if="item.value">X</button>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        tags: Array
    },
    methods: {
        removeTag(item) {
            this.$emit('removeTag', { item: item });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search-tag {
    display: flex;
    flex-wrap: wrap;
    
    .tag {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #333;
        background: #fff;
        border: 1px solid #ccc;
        padding: 0 7px;
        margin-right: 5px;
        margin-bottom: 5px;

        a {
            color: #333;
        }
    }

    button {
        margin-left: 15px;
        padding: 0;
        border: none;
        background: none;
        font-size: 11px;
        color: #999;
    }
}
</style>