import Helper from '@/services/helper';

export default {
    data() {
        return {
            geolocationState: '',
			displayAlertGeolocation: 0, // 0 = Nothing, 1 = Prompt, 2 = Denied
        };
    },
    methods: {
        async checkGeolocation() {
			if (navigator.geolocation) {
				const permission = await navigator?.permissions?.query({name: 'geolocation'});
				this.geolocationState = permission?.state;

				if (this.geolocationState !== 'granted') {
					this.displayAlertGeolocation = 1;

					// Skip get position of first load
					navigator.geolocation.getCurrentPosition(() => {
						this.geolocationState = 'granted';
						this.displayAlertGeolocation = 0;
						this.getStoreResult();
					}, () => {
						// Display user denied geolocation
						this.displayAlertGeolocation = 2;
					});
				}
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดูตำแหน่งได้', 'เว็บบราวเซอร์ที่ท่านใช้ไม่ได้รองรับการค้นหาตำแหน่ง ดังนั้นจึงไม่สามารถเรียงผลลัพธ์ร้านค้าที่ใกล้ที่สุดในตำแหน่งของท่านได้'));
			}
		}
    }
}